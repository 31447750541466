import React, { useState } from 'react';
// import { PhotoSwipe } from 'react-photoswipe'

import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';
import pic6 from '../assets/images/pic06.jpg';
import pic7 from '../assets/images/pic07.jpg';
import pic8 from '../assets/images/pic08.jpg';
import pic9 from '../assets/images/pic09.jpg';
import pic10 from '../assets/images/pic10.jpg';
import pic11 from '../assets/images/pic11.jpg';
import pic12 from '../assets/images/pic12.jpg';
import pic13 from '../assets/images/pic13.jpg';
import pic14 from '../assets/images/pic14.jpg';
import pic15 from '../assets/images/pic15.jpg';
import pic16 from '../assets/images/pic16.jpg';
import pic17 from '../assets/images/pic17.jpg';
import pic18 from '../assets/images/pic18.jpg';
import pic19 from '../assets/images/pic19.jpg';
import pic20 from '../assets/images/pic20.jpg';
import pic21 from '../assets/images/pic21.jpg';
import pic22 from '../assets/images/pic22.jpg';
import pic23 from '../assets/images/pic23.jpg';
import pic24 from '../assets/images/pic24.jpg';
import pic25 from '../assets/images/pic25.jpg';
import pic26 from '../assets/images/pic26.jpg';
import pic27 from '../assets/images/pic27.jpg';
import pic28 from '../assets/images/pic28.jpg';
import pic29 from '../assets/images/pic29.jpg';
import pic30 from '../assets/images/pic30.jpg';
import pic31 from '../assets/images/pic31.jpg';
import pic32 from '../assets/images/pic32.jpg';
import pic33 from '../assets/images/pic33.jpg';
import pic34 from '../assets/images/pic34.jpg';
import pic35 from '../assets/images/pic35.jpg';
import Image from '../components/Image';
import Layout from '../components/Layout';

// import './gallery.css';

const images = [
  { img: pic1, alt: 'showcase-1' },
  { img: pic2, alt: 'showcase-2' },
  { img: pic3, alt: 'showcase-3' },
  { img: pic4, alt: 'showcase-4' },
  { img: pic5, alt: 'showcase-5' },
  { img: pic6, alt: 'showcase-6' },
  { img: pic7, alt: 'showcase-7' },
  { img: pic8, alt: 'showcase-8' },
  { img: pic9, alt: 'showcase-9' },
  { img: pic10, alt: 'showcase-10' },
  { img: pic11, alt: 'showcase-11' },
  { img: pic12, alt: 'showcase-12' },
  { img: pic13, alt: 'showcase-13' },
  { img: pic14, alt: 'showcase-14' },
  { img: pic15, alt: 'showcase-15' },
  { img: pic16, alt: 'showcase-16' },
  { img: pic17, alt: 'showcase-17' },
  { img: pic18, alt: 'showcase-18' },
  { img: pic19, alt: 'showcase-19' },
  { img: pic20, alt: 'showcase-20' },
  { img: pic21, alt: 'showcase-21' },
  { img: pic22, alt: 'showcase-22' },
  { img: pic23, alt: 'showcase-23' },
  { img: pic24, alt: 'showcase-24' },
  { img: pic25, alt: 'showcase-25' },
  { img: pic27, alt: 'showcase-26' },
  { img: pic26, alt: 'showcase-27' },
  { img: pic28, alt: 'showcase-28' },
  { img: pic29, alt: 'showcase-29' },
  { img: pic30, alt: 'showcase-30' },
  { img: pic31, alt: 'showcase-31' },
  { img: pic32, alt: 'showcase-32' },
  { img: pic33, alt: 'showcase-33' },
  { img: pic34, alt: 'showcase-34' },
  { img: pic35, alt: 'showcase-35' },
].reverse();


const IndexPage = () => {
  const [sliderIndex, setSliderIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  const onOpen = (index) => {
    setSliderIndex(index);
    setOpen(true);
  };

  const sliderImages = images.map((image, index) => ({ src: image.img, title: `image ${index + 1}` }));

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Gallery</h2>
          <p>A showcase of some of our past projects</p>
        </header>
        <section className="wrapper style5">
          <>
            {images.length > 0 && (
              <div className="Gallery Dynamic">
                {images.map((image, index) => (
                  <figure
                    className="Gallery--Item"
                    key={`${image.alt}${index}`}
                    onClick={() => onOpen(index)}
                  >
                    <div>
                      <Image
                        resolutions="small"
                        src={image.img}
                        alt={image.alt}
                      />
                    </div>
                  </figure>
                ))}
              </div>
            )}
            {/* {open &&
              sliderImages.length > 0 && (
                <PhotoSwipe
                  isOpen={open}
                  items={sliderImages}
                  options={{
                    index: sliderIndex,
                    history: false
                  }}
                  onClose={() => this.isOpen(false)}
                />
              )} */}
          </>
        </section>
      </article>
    </Layout>
  );
};

export default IndexPage;
